<script lang="ts">
    import { selectedPOS } from '../dataAPI';
    import { posStore } from '../dataStore';
</script>

<h2>Point of Sales</h2>
<ul>
    {#each $posStore as { labelPOS, idPOS } (idPOS)}
        <li class:selected={idPOS === $selectedPOS?.idPOS}>
            {labelPOS}
        </li>
    {/each}
</ul>
