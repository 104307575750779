<script lang="ts">
    export let offset1 = '0%';
    export let offset2 = '50%';
</script>

<defs>
    <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset={offset1} style="stop-color:rgb(0,0,0);stop-opacity:1" />
        <stop offset={offset2} style="stop-color:rgb(0,0,0);stop-opacity:0" />
    </linearGradient>
</defs>

<rect x="0" y="0" width="100%" height="100%" fill="url(#grad1)" />

<style>
    rect {
        pointer-events: none;
    }
</style>
