<script lang="ts">
    import { festivalStore } from '../dataStore';
    import { festivalID, showUI } from '../uiState';

    function handleChange(e) {
        festivalID.set(parseInt(e.target.value));
    }
</script>

<select class:hide={!$showUI} on:change={handleChange}>
    <option hidden>Select festival</option>
    {#each $festivalStore as { id, organisation }}
        <option value={id} selected={id === $festivalID}>{organisation}</option>
    {/each}
</select>

<style>
    select {
        background: black;
        color: white;
        position: absolute;
        z-index: 2;
        left: 2rem;
        top: 2rem;
    }

    .hide {
        visibility: hidden;
        display: none;
    }
</style>
