<script lang="ts">
    import { now } from 'svelte/internal';

    import { items } from '../dataAPI';
    import scroll from '../scroll';
    import { itemID, timeShift } from '../uiState';

    let focused = false;
</script>

<h2>Items</h2>
<ul
    on:mouseenter={() => (focused = true)}
    on:mouseleave={() => (focused = false)}
>
    {#each $items as { label, id, price, createdAt, repartitionID: repID } (id)}
        <li use:scroll={!focused && id === $itemID}>
            <span
                class:selected={id === $itemID}
                on:mousedown={() => {
                    itemID.set(id);
                    timeShift.set(+createdAt - Date.now());
                }}
                >{id} ({createdAt.toLocaleString()}) {label}
                ({price}
                €)</span
            >
        </li>
    {/each}
</ul>

<style>
    li:hover {
        cursor: pointer;
    }
</style>
