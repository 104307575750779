import { writable } from 'svelte/store';
import { tweened, spring } from 'svelte/motion';
import config from './config';
const { hideDelay, updateInterval } = config;
const initShift = 0;
// const initShift = -(Date.now() - 1572922153888);
export const festivalID = writable(31);
export const itemID = writable(null);
// temporal reference point for simulation
export const timeShift = spring(initShift);
// in minutes
export const timeWindowSize = tweened(5, { duration: updateInterval * 2 });
export const showDataExplorer = writable(false);
let uiTimeout;
export const { set: setUI, update, subscribe, } = writable(false, () => {
    return () => {
        clearTimeout(uiTimeout);
    };
});
export const showUI = {
    set: (value) => {
        clearTimeout(uiTimeout);
        setUI(value);
        uiTimeout = setTimeout(() => {
            setUI(false);
        }, hideDelay);
    },
    update,
    subscribe,
};
