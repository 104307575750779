<script lang="ts">
    export let width = 20;
</script>

<div>
    <svg
        width="282"
        height="325"
        viewBox="0 0 282 325"
        style="width: {width}px; height: auto;"
    >
        <path
            fill="#FFF"
            stroke="none"
            d="M72.1,203.4 C70.6,202.6 69.5,200.5 69.5,198.8 L69.5,71.8 C69.5,70.1 70.9,68.7 72.6,68.7 L121.4,68.7 C123.1,68.7 125.7,69.4 127.2,70.2 L209.7,117.8 C211.2,118.6 212.3,120.7 212.3,122.4 L212.3,198.7 C212.3,200.4 211.1,202.5 209.7,203.3 L143.6,241.4 C142.1,242.2 139.7,242.2 138.2,241.4 L72.1,203.4 Z M3.3,0 C1.5,0 0,1.5 0,3.3 L0,240.7 C0,242.5 1.3,244.7 2.8,245.6 L137.9,323.6 C139.5,324.5 142,324.5 143.6,323.6 L278.7,245.6 C280.3,244.7 281.5,242.5 281.5,240.7 L281.5,84.6 C281.5,82.8 280.2,80.6 278.7,79.7 L143.6,1.7 C142,0.8 139.3,0.1 137.5,0.1 L3.3,0 L3.3,0 Z"
        />
    </svg>
    <span>Run Fairly</span>
</div>

<style>
    svg {
        margin-right: 0.5rem;
    }

    div {
        display: flex;
        align-items: center;
    }

    span {
        font-weight: 700;
    }
</style>
