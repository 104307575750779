<script lang="ts">
    import config from '../config';
    import { logo } from '../dataAPI';
    import BMALogo from './Logo.svelte';
</script>

<header>
    <BMALogo />

    {#if $logo}
        <img src="{config.host}/{$logo}" alt="logo" />
    {/if}
</header>

<style>
    header {
        position: absolute;
        top: 2rem;
        right: 2rem;
        display: flex;
    }

    img {
        height: 48px;
        background: white;
        margin-left: 2rem;
    }
</style>
