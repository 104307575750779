export const colors = {
    blue: '#6f96cb',
    red: '#f3715a',
};
const theme = {
    mainColor: colors.blue,
    highlightColor: colors.red,
    fontSize: 14,
};
export default theme;
