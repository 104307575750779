<script lang="ts">
    import { fade } from 'svelte/transition';
    import { timeFormat } from 'd3-time-format';
    import { timeDay, timeMonth } from 'd3-time';
    import theme from '../theme';

    export let scale;
    export let y;
    export let height;
    export let time;
    export let color = '#666';
    export let showVerticalLine = true;
    export let showTime = true;

    const tFormat = timeFormat('%H:%M:%S');

    $: ticks = scale.ticks(4);
    $: [start, end] = scale.domain();
    $: timeX = scale(time);

    $: format =
        timeMonth.count(start, end) > 1
            ? timeFormat('%m/%y')
            : timeDay.count(start, end) > 1
            ? timeFormat('%a %d')
            : tFormat;
</script>

<g>
    <line x1={scale(start)} y1={y} x2={scale(end)} y2={y} stroke={color} />
    {#each ticks as tick (+tick)}
        <g
            style="transform: translate({scale(tick)}px,{y}px);"
            in:fade
            out:fade
        >
            <text
                x="0"
                y="24"
                fill="white"
                text-anchor="middle"
                font-size={theme.fontSize}
                >{format(tick)}
            </text>
            <line x1="0" y1="0" x2="0" y2="8" stroke={color} />
        </g>
    {/each}

    {#if timeX}
        {#if showVerticalLine}
            <line x1={timeX} y1={y - height} x2={timeX} y2={y} stroke={color} />
        {/if}
        {#if showTime}
            <text
                x={timeX}
                y={y + 48}
                fill="white"
                text-anchor="middle"
                font-weight="bold"
                font-size={theme.fontSize}>{tFormat(time)}</text
            >
        {/if}
    {/if}
</g>
