function scroll(node, sel) {
    // the node has been mounted in the DOM
    return {
        update(selected) {
            // the value of `bar` has changed
            if (selected) {
                node.scrollIntoView({ behavior: 'smooth' });
            }
        },
        destroy() {
            // the node has been removed from the DOM
        },
    };
}
export default scroll;
