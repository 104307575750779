import { derived } from 'svelte/store';
import { extent, max } from 'd3-array';
import { beneficiaryStore, configStore, festivalStore, itemStore, posStore, repartitionStore, } from './dataStore';
import { itemID, festivalID } from './uiState';
/**
 * Returns TRUE if any store is empty
 */
export const isLoading = derived([
    itemStore,
    posStore,
    beneficiaryStore,
    festivalStore,
    configStore,
    repartitionStore,
], (all) => {
    return all.some((store) => store.length === 0);
});
/**
 * Returns the selected festival
 */
export const selectedFestival = derived([festivalID, festivalStore], ([$id, $festivalStore]) => {
    return $festivalStore.find((f) => f.id === $id);
});
/**
 * Returns all items and add paritions for beneficiaries
 */
export const items = derived([itemStore, repartitionStore], ([$itemStore, $repartitionStore]) => {
    return $itemStore.map((item) => {
        const { idPItem, POS_id, createdAt, labelItem, prixItem, repartition_id, utilisateur_id, } = item;
        const repartition = $repartitionStore.find((r) => r.id === repartition_id);
        const partitions = !repartition
            ? null
            : new Map(repartition.Rules.map(({ beneficiaire_id, pourcentage }) => {
                const pricePart = (prixItem * pourcentage) / 100;
                return [beneficiaire_id, pricePart];
            }));
        return {
            id: idPItem,
            posID: POS_id,
            createdAt: new Date(createdAt),
            label: labelItem,
            price: prixItem,
            repartitionID: repartition_id,
            userID: utilisateur_id,
            partitions,
        };
    });
});
/**
 * Returns the max price of all items
 */
export const maxPrice = derived(items, ($items) => max($items, (item) => item.price));
/**
 * Returns list of beneficiaries with rounded values and alphabetically sorted
 */
export const beneficiaries = derived(beneficiaryStore, ($beneficiaryStore) => {
    return $beneficiaryStore
        .map((b) => {
        const rounded = Math.round(b.value);
        return Object.assign(Object.assign({}, b), { value: rounded });
    })
        .sort(({ name: b1 }, { name: a1 }) => {
        const a = a1.toLocaleLowerCase();
        const b = b1.toLocaleLowerCase();
        return a > b ? -1 : a < b ? 1 : 0;
    });
});
/**
 * Returns the selected item
 */
export const selectedItem = derived([itemID, items], ([$itemID, $items]) => {
    return $items.find((item) => item.id === $itemID);
});
/**
 * Returns repartition of the selected item
 */
export const selectedRepartition = derived([selectedItem, repartitionStore], ([$item, $repartitionStore]) => {
    if (!$item)
        return null;
    return $repartitionStore.find((rep) => rep.id === $item.repartitionID);
});
/**
 * Returns POS of the selected item
 */
export const selectedPOS = derived([selectedItem, posStore], ([$item, $posStore]) => {
    if (!$item)
        return null;
    return $posStore.find((pos) => pos.idPOS === $item.posID);
});
/**
 * Logo for selected festival
 */
export const logo = derived(configStore, ($config) => {
    const item = $config.find((c) => c.key === 'bg_mobile');
    return (item === null || item === void 0 ? void 0 : item.value) || null;
});
/**
 * Favicon for selected festival
 */
export const favicon = derived(configStore, ($config) => {
    const item = $config.find((c) => c.key === 'favicon');
    return (item === null || item === void 0 ? void 0 : item.value) || null;
});
/**
 * Returns the time span that contains all items of the selected festival
 */
export const timeSpan = derived([items, configStore], ([$items, $config]) => {
    const start = $config.find((c) => c.key === 'date_from');
    const end = $config.find((c) => c.key === 'date_to');
    let span = null;
    // try to get information from config
    if (start && end) {
        span = [new Date(start.value), new Date(end.value)];
        // derive time span from items
    }
    else {
        span = extent($items.map((item) => item.createdAt));
    }
    return span;
});
