<script lang="ts">
    import { selectedRepartition } from '../dataAPI';

    import { repartitionStore } from '../dataStore';
    import scroll from '../scroll';

    $: repartitionID = $selectedRepartition?.id;
</script>

<h2>Repartitions</h2>
<ul>
    {#each $repartitionStore as { nom, Rules, id: repID } (repID)}
        <li use:scroll={repID === repartitionID}>
            <span class:selected={repID === repartitionID}>
                {nom}
            </span>
            <ul>
                {#each Rules as { id, pourcentage, Beneficiaire } (id)}
                    <li
                        class:listItem_nested_active={repID === repartitionID}
                        class="listItem_nested"
                    >
                        {pourcentage}% {Beneficiaire?.nom || '?'}
                    </li>
                {/each}
            </ul>
        </li>
    {/each}
</ul>

<style>
    .listItem_nested {
        margin-left: 1rem;
    }

    .listItem_nested_active {
        color: white;
    }
</style>
