<script lang="ts">
    import { onMount } from 'svelte';
    import { fade } from 'svelte/transition';
    import { throttle } from 'lodash';
    import { scaleTime, scaleBand, scaleSqrt, scaleLinear } from 'd3-scale';
    import { timeMinute } from 'd3-time';
    import { interval } from 'd3-timer';
    import { forceSimulation, forceCollide, forceX, forceY } from 'd3-force';
    import { beneficiaries, items, maxPrice, timeSpan } from '../dataAPI';
    import { itemID, showUI, timeShift, timeWindowSize } from '../uiState';
    import Axis from './Axis.svelte';
    import Circle from './Circle.svelte';
    import Beneficiary from './Beneficiary.svelte';
    import ItemLabel from './ItemLabel.svelte';
    import type { Node, ItemSimple } from '../types';
    import Gradient from './Gradient.svelte';
    import { clampRounded, formatDec } from '../utils';
    import theme from '../theme';
    import config from '../config';

    const {
        sensitivity,
        timeWindowMin,
        timeWindowMax,
        frequencyLow,
        frequencyHigh,
    } = config;

    const { fontSize, highlightColor } = theme;

    export let width = 800;
    export let height = 500;

    const padLeft = 64;
    const padRight = 96;
    const padTop = 160;
    const padBottom = 48;
    const spacingBottom = 48;

    const monitorScale = scaleLinear().rangeRound([
        timeWindowMax,
        timeWindowMin,
    ]);
    const monitorWindowSize = monitorScale(sensitivity);

    let now;
    let itemFreq = 0;
    let nodes: Node[] = [];
    let latestItem: ItemSimple = null;
    let simulation = forceSimulation()
        .alphaDecay(0)
        .on('tick', () => {
            now = Date.now() + $timeShift;
            // trigger update
            nodes = simulation.nodes();
        })
        .stop();

    $: partitionedItems = $items.filter((item) => item.partitions?.size > 0);
    $: timeFrameStart = now - $timeWindowSize * 1000 * 60;
    $: right = width - padRight;
    $: bottom = height - padBottom;
    $: bottomViz = bottom - spacingBottom;

    onMount(() => {
        simulation.restart();
        createNodes();

        const windowScale = scaleLinear()
            .domain([frequencyLow, frequencyHigh])
            .rangeRound([timeWindowMax, timeWindowMin])
            .clamp(true);

        const updateInterval = interval(() => {
            createNodes();

            timeWindowSize.set(windowScale(itemFreq));
        }, config.updateInterval);

        return () => {
            simulation.stop();
            updateInterval.stop();
        };
    });

    // *************************************************
    // SCALES
    // *************************************************

    $: xScale = scaleTime()
        .domain($timeSpan)
        .rangeRound([padLeft, right])
        .nice();

    $: xScaleRealtime = scaleTime()
        .domain([timeFrameStart, now])
        .range([padLeft, right]);

    $: yScale = scaleBand()
        .domain($beneficiaries.map((b) => b.idBenef))
        .rangeRound([padTop, bottomViz]);

    $: rScale = scaleSqrt().domain([0, $maxPrice]).rangeRound([0, 40]);

    // *************************************************
    // FORCES
    // *************************************************

    $: collideForce = forceCollide()
        .radius((n: Node) => n.r + 4)
        .strength(0.8);

    $: xForce = forceX()
        .x((n: Node) => xScaleRealtime(n.data.createdAt))
        .strength(0.01);

    $: yForce = forceY()
        .y((n: Node) => yScale(n.data.beneficiaryID))
        .strength(0.01);

    $: simulation.force('collide', collideForce);
    $: simulation.force('x', xForce);
    $: simulation.force('y', yForce);

    // *************************************************
    // DATA
    // *************************************************

    const createNodes = throttle(
        () => {
            const newNodes = [];
            let count = 0;
            const monitorStart = +timeMinute.offset(now, -monitorWindowSize);

            // loop through all items
            for (let i = 0; i < partitionedItems.length; i++) {
                const item = partitionedItems[i];
                const ms = +item.createdAt;
                const inPast = ms <= now;

                if (inPast) {
                    const itemX = xScaleRealtime(item.createdAt);

                    // monitor number of items within the last few minutes
                    if (ms >= monitorStart) count++;

                    // lastItem will be the last item within the time frame
                    if (ms >= timeFrameStart) latestItem = item;

                    let index = 0;
                    item.partitions.forEach((pricePart, beneficiaryID) => {
                        // each partition gets a unique id
                        const id = item.id + '_' + beneficiaryID;

                        // restore previous position
                        const prev = simulation
                            .nodes()
                            .find((node: Node) => node.id === id);

                        const x = prev?.x || itemX;

                        // only add visible nodes
                        if (x >= -48) {
                            newNodes.push(<Node>{
                                id,
                                x,
                                y: prev?.y || bottom,
                                r: rScale(pricePart),
                                data: {
                                    createdAt: +item.createdAt,
                                    beneficiaryID,
                                    price: pricePart,
                                    itemID: item.id,
                                    hasLabel: index === 0,
                                },
                            });
                        }

                        index++;
                    });
                } else {
                    break;
                }
            }

            itemFreq = count / monitorWindowSize;
            simulation.nodes(newNodes);
        },
        1000,
        { leading: true }
    );

    $: if ($timeShift || $timeWindowSize) createNodes();

    function setTime(t: number) {
        timeShift.set(t - Date.now());
    }

    function resetItem() {
        itemID.set(null);
    }
</script>

<svg
    {width}
    {height}
    viewBox="0 0 {width} {height}"
    on:wheel={(e) => {
        timeWindowSize.update(
            (s) => clampRounded(s + e.deltaY / 5, timeWindowMin, timeWindowMax),
            { duration: 0 }
        );
    }}
>
    {#each nodes as node (node.id)}
        <Circle
            x={node.x}
            y={node.y}
            r={node.r}
            highlight={node.data.itemID === $itemID ||
                node.data.itemID === latestItem?.id}
            label={node.data.itemID === latestItem?.id
                ? '+' + formatDec(node.data.price)
                : null}
            onEnter={() => itemID.set(node.data.itemID)}
            onLeave={resetItem}
            onClick={() => {
                setTime(node.data.createdAt);
            }}
        />
        {#if latestItem && node.data.itemID === latestItem.id && node.data.hasLabel}
            <ItemLabel
                x={node.x - node.r - 8}
                y={bottom - 32}
                fill={highlightColor}
                label={latestItem.label}
            />
        {/if}
    {/each}

    <Gradient
        offset1="{Math.round((200 / width) * 100)}%"
        offset2="{Math.round((500 / width) * 100)}%"
    />

    {#if $showUI}
        <Axis
            scale={xScale}
            y={bottom}
            time={now}
            height={bottom}
            showVerticalLine={false}
            showTime={false}
        />
        {#each partitionedItems as item (item.id)}
            <Circle
                x={xScale(item.createdAt)}
                y={bottom}
                r={4}
                highlight={item.id === $itemID}
                color="rgba(255, 255, 255, 0.2)"
                fill={true}
                onEnter={() => itemID.set(item.id)}
                onLeave={resetItem}
                onClick={() => setTime(+item.createdAt)}
            />
        {/each}
    {:else}
        <Axis
            scale={xScaleRealtime}
            y={bottom}
            time={now}
            height={bottom}
            showVerticalLine={false}
            showTime={false}
        />
    {/if}

    <!-- List of beneficiaries -->
    {#each $beneficiaries as { name, idBenef, value } (idBenef)}
        <Beneficiary
            {name}
            {value}
            x={padLeft + 64 + 16}
            y={yScale(idBenef)}
            fill={latestItem?.partitions.get(idBenef)
                ? highlightColor
                : 'white'}
        />
    {/each}
</svg>

<style>
    svg {
        user-select: none;
        position: absolute;
        top: 0;
        left: 0;
    }
</style>
