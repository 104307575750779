<script lang="ts">
    import { fade } from 'svelte/transition';

    export let x;
    export let y;
    export let fill;
    export let label;
</script>

<text
    x="0"
    y="0"
    {fill}
    text-anchor="end"
    font-family="Rubik Mono One"
    font-size="18"
    font-weight="bold"
    style="transform:translate({x}px,{y}px)"
    out:fade={{ duration: 200 }}
    in:fade={{ duration: 500, delay: 1000 }}>{label}</text
>
