import { derived, readable, get } from 'svelte/store';
import { festivalID } from './uiState';
import config from './config';
const { host, pollingInterval } = config;
async function requestAPI(endpoint, headers = null) {
    try {
        const response = await fetch(`${host}/api/${endpoint}`, {
            method: 'GET',
            headers: new Headers(Object.assign({ 'content-type': 'Application/json' }, headers)),
        });
        const result = await response.json();
        return result;
    }
    catch (e) {
        console.log(e);
    }
}
/**
 * Store contains all festivals
 */
export const festivalStore = readable([], (set) => {
    requestAPI('mobile/of').then((result) => {
        set(result);
    });
});
/**
 * Store contains all items for selected festival
 */
export const itemStore = derived(festivalID, ($id, set) => {
    const request = (check) => {
        requestAPI(`/mobile/items/${$id}`).then((result) => {
            // only update store if we got more items
            if (!check || result.length !== get(itemStore).length) {
                set(result);
            }
        });
    };
    request(false);
    const id = setInterval(() => request(true), pollingInterval);
    return () => {
        clearInterval(id);
    };
}, []);
/**
 * Store contains all POS for selected festival
 */
export const posStore = derived(festivalID, ($id, set) => {
    requestAPI(`mobile/pos/${$id}`).then((result) => {
        set(result);
    });
}, []);
/**
 * Store contains all repartitions for selected festival
 */
export const repartitionStore = derived(festivalID, ($id, set) => {
    requestAPI(`mobile/of/${$id}/repartitions`).then((result) => {
        set(result.repartitions);
    });
}, []);
/**
 * Store contains all beneficiaries for selected festival
 */
export const beneficiaryStore = derived(festivalID, ($id, set) => {
    const request = () => {
        requestAPI(`mobile/data`, {
            'x-id': $id,
        }).then((result) => {
            set(result.data);
        });
    };
    request();
    const id = setInterval(request, pollingInterval);
    return () => {
        clearInterval(id);
    };
}, []);
export const configStore = derived(festivalID, ($id, set) => {
    requestAPI(`mobile/config`, {
        'x-id': $id,
    }).then((result) => {
        set(result);
    });
}, []);
