<script lang="ts">
    import { selectedItem } from '../dataAPI';
    import { beneficiaryStore } from '../dataStore';

    $: partitions = $selectedItem?.partitions;
</script>

<h2>Beneficiaries</h2>
<ul>
    {#each $beneficiaryStore as { name, value, idBenef } (idBenef)}
        <li class:selected={partitions?.get(idBenef)}>
            <span>[{value} €]</span>
            {name}

            {#if partitions?.get(idBenef)}
                <span class="part"
                    >{'+'}
                    {partitions?.get(idBenef)} €</span
                >
            {/if}
        </li>
    {/each}
</ul>

<style>
    .part {
        color: white;
        font-weight: bold;
    }
</style>
