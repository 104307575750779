<script lang="ts">
    import { favicon, isLoading, logo, selectedFestival } from './dataAPI';
    import {
        showDataExplorer,
        showUI,
        timeShift,
        timeWindowSize,
    } from './uiState';
    import FestivalSelector from './components/FestivalSelector.svelte';
    import Timeline from './components/Timeline.svelte';
    import config from './config';
    import DataExplorer from './components/DataExplorer.svelte';
    import Header from './components/Header.svelte';

    const { host } = config;

    let width;
    let height;

    // update title
    $: document.title = $selectedFestival?.organisation || 'blockchain my art';
</script>

<svelte:window bind:innerWidth={width} bind:innerHeight={height} />
<svelte:head>
    <link
        rel="icon"
        type="image/png"
        href={$favicon ? `${host}/${$favicon}` : '/favicon.png'}
    />
</svelte:head>
<svelte:body
    on:mousemove={() => {
        showUI.set(true);
    }}
    on:keydown={(key) => {
        if (key.code === 'Space') showDataExplorer.update((d) => !d);
        if (key.code === 'ArrowRight')
            timeShift.update((shift) => shift + $timeWindowSize * 3000);
        if (key.code === 'ArrowLeft')
            timeShift.update((shift) => shift - $timeWindowSize * 3000);
    }} />

<Header />
<main>
    <FestivalSelector />
    {#if $isLoading}
        Loading...
    {/if}
    {#if $showDataExplorer}
        <DataExplorer />
    {/if}

    <Timeline {width} {height} />
</main>
