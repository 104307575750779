<script lang="ts">
    import theme from '../theme';
    import { formatRound } from '../utils';

    export let x;
    export let y;
    export let name;
    export let value;
    export let fill = 'white';
</script>

<text
    x={x + 16}
    {y}
    font-size={theme.fontSize}
    font-weight="600"
    dominant-baseline="central"
    {fill}
>
    {name}
</text>
<text
    {x}
    {y}
    font-size={theme.fontSize}
    font-weight="700"
    fill="white"
    text-anchor="end"
    dominant-baseline="central">{formatRound(value)}</text
>
