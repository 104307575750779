<script lang="ts">
    import { fade, scale } from 'svelte/transition';
    import { cubicInOut } from 'svelte/easing';
    import theme from '../theme';

    export let x;
    export let y;
    export let r;
    export let highlight;
    export let label = null;
    export let color = theme.mainColor;
    export let fill = true;
    export let onEnter;
    export let onLeave;
    export let onClick;

    $: labelX = r + 8;
</script>

<g
    on:mouseenter={onEnter}
    on:mouseleave={onLeave}
    style="transform: translate({x}px,{y}px);"
>
    <circle
        cx={0}
        cy={0}
        {r}
        fill-opacity={1}
        fill={fill ? (highlight ? theme.highlightColor : color) : 'transparent'}
        stroke={highlight ? theme.highlightColor : color}
        stroke-width="0"
        stroke-opacity="1"
        in:scale={{ duration: 600, easing: cubicInOut }}
        out:scale={{ duration: 600, easing: cubicInOut }}
        on:mousedown={onClick}
    />
    {#if label && highlight}
        <g in:fade={{ duration: 500, delay: 3000 }} out:fade>
            <text
                dominant-baseline="central"
                font-size={theme.fontSize}
                x={labelX}
                y="0"
                text-anchor="start"
                stroke-width={4}
                stroke="black"
                stroke-opacity={0.7}
                fill="transparent"
                font-weight="bold"
                stroke-linecap="round"
                stroke-linejoin="bevel">{label}</text
            >

            <text
                x={labelX}
                y="0"
                fill="#fff"
                dominant-baseline="central"
                text-anchor="start"
                font-size={theme.fontSize}
                font-weight="bold">{label}</text
            >
        </g>
    {/if}
</g>

<style>
    circle:hover {
        cursor: pointer;
    }
</style>
